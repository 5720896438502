var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['cursor-pointer', 'flex-1', 'flex', 'justify-center'],on:{"click":_vm.selectTab}},[_c('h3',{class:[
      'text-center',
      'uppercase',
      'font-normal',
      'text-base',
      'leading-7',
      _vm.tabStyle,
    ]},[_vm._v(_vm._s(_vm.text))])])
}
var staticRenderFns = []

export { render, staticRenderFns }